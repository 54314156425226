<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-2">我意外懷孕了</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-3-1">我想要生下來</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="heartTohearts-4-1">親密關係</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><router-link to="">懷孕性行為</router-link></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="title-box">
              <p class="step-text" data-aos="fade-up" >ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up" >選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up" >為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="step-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜懷孕性行為｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>懷孕做愛行不行？</h3>
                        <p>懷孕做愛可以嗎? 懷孕多久可以做愛？擔心懷孕性行為影響胎兒？懷孕打砲不是罪，但聽說懷孕初期做愛會流產、懷孕後期做愛會催生是真的嗎？別擔心，從懷孕做愛姿勢到懷孕性行為出血處理，帶你剖析孕期性行為實際面！<br>
                        <img class="rounded-0 w-100 pt-2" src="https://images.ctfassets.net/i2o3e6knau3c/3QJxHpe6VkJU1xb2yjjpKX/4680c4f6c6041c34b27d77c5744c8804/__________________________________________________________________.jpg?w=1028&h=878&q=100&fm=webp" alt=""></p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>一、懷孕性行為行不行？各位，懷孕打砲不是罪！</h3>
                        <p>懷孕可以做愛嗎？當驗孕棒出現了兩條線，一場漫長的旅程正要展開，兩人的甜蜜生活起了一些變化，是不是就要從此開始禁慾了呢？懷孕想做愛難道要忍嗎？
我知道懷孕期間性行為與否你可能不一定在意，但對你老公來說應該是很重要。想知道懷孕期間做愛大小事，就看下去吧！</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>（一）懷孕可以做愛嗎？別怕！整個孕期都是你的性愛期！</h3>
                        <p>「懷孕可以性行為嗎？」、「孕婦可以做愛嗎？」、「懷孕多久可以做愛？」、「懷孕打炮會傷害小孩嗎？」這類的問題，不知道有多少產檢夫妻問過我，畢竟懷胎九個多月，如果要禁止孕期性行為，實在有點慘絕人寰！<br><br>
                        所以如果你問我懷孕能做愛嗎？懷孕可以有性行為嗎？在產婦、胎兒都健康的情況下，懷孕性行為當然可以，如果你願意，整個孕期都是你的性愛期！
太重要了必須重複三次：「懷孕可以做愛」、「懷孕可以做愛」、「懷孕可以做愛」
                        </p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>（二）孕期性行為無害！但這些族群要當心！</h3>
                        <p>這篇文章的目的是要消弭各位的不必要的擔心、破解不正確的謠言，對於健康的族群來說懷孕性行為確實不會造成負面影響，但如果是有腹痛、出血...等不適，或有其他疾病的產婦，懷孕期間性行為可就不能用同一套標準看待！</p>
                        <img class="rounded-0 w-100 pt-2" src="https://images.ctfassets.net/i2o3e6knau3c/5z3dRW7LZU1fgE2Rr4pQJr/4dc57f4e6f7cec1e5cb1510d17c62299/_______________________________________.jpg" alt="">
                      </li>
                      <li data-aos="fade-up">
                        <h3 class="pt-5">二、懷孕初期做愛會流產？懷孕後期做愛會催生？</h3>
                        <p>懷孕多久可以做愛？懷孕初期可以做愛嗎？聽說懷孕初期做愛會造成流產、懷孕後期做愛會有催生效果導致早產，這些是真的嗎？</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>（一）懷孕初期做愛流產是謠言！但三狀況要當心！</h3>
                        <p>民間習俗說懷孕前三個月不公開是因為不穩定我能理解，但懷孕初期性行為可能導致流產這件事，是沒有任何科學根據的，當然如果在懷孕性行為中出現以下狀況請務必立即停止並就醫檢查：<br>
                        1.劇烈腹痛 <br>
                        2.嚴重宮縮 <br>
                        3.異常出血
                        </p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>（二）懷孕後期做愛會催生？太看得起先生影響力了吧！？</h3>
                        <p>平安無事、性福美滿的渡過了懷孕初期後，來到肚子變大、身體負擔變重的懷孕後期，看著圓滾滾隆起的腹部，大家不免又心生擔憂，對懷孕後期性行為安全與否有所疑慮，特別是擔心撞擊與先生精液的影響下，是否有可能造成胎兒早產？
確實男性所分泌的前列腺素理論上會有催生的效果，但請各位無須緊張，婦產科教科書上也證實了：「懷孕後期性行為的頻率與最終生產週數無關」，我想是劑量不足以達到真正的催生效果吧！</p>
                        <img class="rounded-0 w-100 pt-2" src="https://images.ctfassets.net/i2o3e6knau3c/FmohkyTlKZGFmch5dQDs4/1eee9f954b903e0cf2e22b46a51f669f/layout.jpg" alt="">
                      </li>
                      <li data-aos="fade-up">
                        <h3 class="pt-5">三、懷孕做愛姿勢怎麼喬？會頂到我小孩嗎！？</h3>
                        <p>關於懷孕可以做愛嗎？民間說法眾多，有人擔心懷孕性行為會影響胎兒、有男性朋友對於尺寸很有自信怕會頂到胎兒，當然也有人因為產婦肚子太大造成孕期性行為姿勢喬不攏而就此放棄，到底哪些是友善懷孕做愛姿勢呢？</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>（一）再怎麼有自信也不太會頂到小孩的頭！</h3>
                        <p>母體構造中的子宮頸、羊膜囊和子宮肌肉都能提供胎兒充分的保護，因此關於懷孕性生活會影響胎兒這塊無須擔心。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>（二）沒有不舒服就是良好的孕婦做愛姿勢！</h3>
                        <p>雖然我只是一位婦產科醫師，不是性學大師，但畢竟「性」也是孕期生活中的一環，難免會遇到大家諮詢懷孕做愛姿勢的相關問題。
                            我想懷孕初期性行為由於身形還沒有太大的變化，只要體力能負荷，姿勢上是能隨心所欲的，而大多數問題可能發生在大腹便便的懷孕中後期。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>懷孕做愛姿勢怎麼喬？</h3>
                        <p>隨著胎兒成長，產婦腹部越來越大，懷孕後期性行為已經不適合常見的傳教士姿勢，為了減輕產婦的負擔，又能避免壓迫到腹部，建議採取能錯開腹部，且不會讓肚子懸空的姿勢，其他還有什麼姿勢就交給各位去研究了！</p>
                        <img class="rounded-0 w-100 pt-2" src="https://images.ctfassets.net/i2o3e6knau3c/2uJ60sMwmTTGLKZW9Hsh2Z/8d1e0b84b5c928d724ceba5df5e1a375/______________________________.jpg" alt="">
                      </li>
                      <li data-aos="fade-up">
                        <p>參考來源：<a class="fs-5 text-danger" href="https://haveababy.tw/posts/the-correct-concept-of-pregnancy-sex" target="_blank">Dr. Willams《懷孕做愛行不行？會影響胎兒嗎？可以無套嗎？姿勢怎麼喬？》</a></p>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  components: {
    Consult
  }
}
</script>
